<template>
  <div>
    <b-modal
        :id="'swap_spec_modal_' + specType"
        :ref="'swap_spec_modal_' + specType"
        title="Swap Specification"
        @ok="saveSwapSpec"
        ok-title="Archive"
        @close="closeModal"
        cancel-title="Go Back"
        cancel-variant="outline-secondary"
        :centered="true"
        body-class="modal-padding"
        :no-close-on-backdrop="true"
    >
      <validation-observer ref="specSwapRules">
        <validation-provider name="name" rules="required" #default="{ errors }">
          <b-form-group
              label="Specification Name"
              label-for="name-input"
              invalid-feedback="Name is required"
          >
            <b-form-input
                id="name-input"
                placeholder="Specification Name"
                required
                v-model="form.name"
                :value="setForm"
            ></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small></b-form-group
          >
        </validation-provider>
        <label for="note">Internal Note</label>
        &nbsp;
        <label for="optional" class="small text-muted">Optional</label>
        <b-form-textarea
            id="name-input"
            required
            v-model="form.internal_note"
            placeholder="Internal notes and reason for changes."
            :state="internalNoteLength <= maxChar"
            :class="internalNoteLength > maxChar ? 'text-danger' : ''"
        ></b-form-textarea>
        <small
            class="textarea-counter-value float-right"
            :class="form.internal_note > maxChar ? 'bg-danger' : ''"
        >
          <span class="char-count">{{ internalNoteLength }}</span> /
          {{ maxChar }}
        </small>
        <label for="note" class="mt-1">Manufacturer</label>
        <validation-provider
            name="manufacturer"
            rules="required"
            #default="{ errors }"
        >
          <b-form-input
              v-model="form.manufacturer"
              id="name-input"
              required
              :state="manufacturerLength <= maxCharManu"
              :class="manufacturerLength > maxCharManu ? 'text-danger' : ''"
          ></b-form-input>
          <small
              class="textarea-counter-value float-right"
              :class="form.manufacturer.length > maxCharManu ? 'bg-danger' : ''"
          >
            <span class="char-count">{{ manufacturerLength }}</span> /
            {{ maxCharManu }}
          </small>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
        <b-row class="mt-1">
          <b-col cols="4">
            <label for="cogs">COGS / Unit</label>
            <validation-provider
                name="COGS / Unit"
                rules="required"
                #default="{ errors }"
            >
              <b-input-group prepend="$">
                <b-form-input
                    v-model="form.unit_cogs"
                    placeholder="0"
                    type="number"
                ></b-form-input> </b-input-group
              ><small class="text-danger">{{
                errors[0]
              }}</small></validation-provider
            >
          </b-col>
          <b-col cols="3">
            <div class="d-flex mt-1">
              <div class="mt-1">
                <uil-times style="width: 20px; height: 20px" />
              </div>
              <div class="ml-2 text-right">
                <p class="pb-0 mb-0" style="font-size:12px: font-weight:400">
                  QTY
                </p>
                <p style="font-size: 18px; font-weight: 600">{{ qty }}</p>
              </div>
            </div>
          </b-col>
          <b-col cols="5">
            <div class="d-flex mt-1">
              <div class="mt-1">
                <uil-grip-horizontal-line style="width: 20px; height: 20px" />
              </div>
              <div class="ml-2">
                <p class="pb-0 mb-0" style="font-size:12px: font-weight:400">
                  Total Price
                </p>
                <p style="font-size: 18px; font-weight: 600">
                  {{
                    Number(qty * form.unit_cogs).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </p>
              </div>
            </div>
          </b-col>
          <b-col cols="1" class="mt-0 pt-0">
            <div class="mt-2">
              <uil-times style="width: 20px; height: 20px" />
            </div>
          </b-col>
          <b-col cols="5" class="mt-0 pt-0">
            <label for="cogs" class="m-0 p-0">Delivery & Installation</label>
            <validation-provider
                name="Delivery & Installation"
                rules="required"
                #default="{ errors }"
            >
              <b-input-group append="%">
                <b-form-input
                    v-model="form.unit_delivery_install"
                    placeholder="22.5"
                    type="number"
                ></b-form-input> </b-input-group
              ><small class="text-danger">{{
                errors[0]
              }}</small></validation-provider
            >
          </b-col>
          <b-col cols="6" class="mt-0 pt-0">
            <div class="d-flex mt-1">
              <div class="mt-1">
                <uil-grip-horizontal-line style="width: 20px; height: 20px" />
              </div>
              <div class="ml-2">
                <p class="pb-0 mb-0" style="font-size:12px: font-weight:400">
                  Total Delivery & Installation
                </p>
                <p style="font-size: 18px; font-weight: 600">
                  {{
                    Number(
                        qty * form.unit_cogs * (form.unit_delivery_install / 100)
                    ).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="primary"
              class="float-right ml-1"
              @click="validateSave()"
          >
            Save
          </b-button>
          <b-button
              variant="outline-secondary"
              class="float-right ml-1"
              @click="resetSpec"
          >
            Reset to Default Spec
          </b-button>
          <b-button
              variant="outline-secondary"
              class="float-right ml-1"
              @click="closeModal"
          >
            Cancel
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCol,
  BRow,
  BTableSimple,
  BTbody,
  BTd,
  BTfoot,
  BTh,
  BThead,
  BTr,
  VBToggle,
  BTabs,
  BTab,
  BNavItem,
  BBadge,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormTextarea,
  BDropdownDivider,
  BFormInput,
  BInputGroup,
} from "bootstrap-vue";

import { UilTimes, UilGripHorizontalLine } from "@iconscout/vue-unicons";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  components: {
    BButton,
    BCard,
    BCardBody,
    BCol,
    BRow,
    BTableSimple,
    BTbody,
    BTd,
    BTfoot,
    BTh,
    BThead,
    BTr,
    VBToggle,
    BTabs,
    BTab,
    BNavItem,
    BBadge,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormTextarea,
    BDropdownDivider,
    BFormInput,
    BInputGroup,
    UilTimes,
    UilGripHorizontalLine,
    // validator components
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    spec: {
      type: Object,
      default: {},
    },
    swaped_spec: {
      type: Object,
      default: {},
    },
    specType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      show: true,
      form: {
        id: null,
        name: "",
        internal_note: "",
        manufacturer: "",
        unit_cogs: 0,
        quantity: 0,
        unit_delivery_install: 22.5,
        total_price: 0,
        total_delivery_install: 0,
        ffe_spec_id: null,
        avit_spec_id: null,
        cnst_spec_id: null,
      },
      emtyForm: {
        id: null,
        name: "",
        internal_note: "",
        manufacturer: "",
        unit_cogs: 0,
        quantity: 0,
        unit_delivery_install: 22.5,
        total_price: 0,
        total_delivery_install: 0,
        ffe_spec_id: null,
        avit_spec_id: null,
        cnst_spec_id: null,
      },
      oldValueForm: {},
      maxChar: 500,
      maxCharManu: 100,
    };
  },
  computed: {
    qty() {
      return this.spec.qty;
    },
    spec_id() {
      return this.spec.id;
    },
    spec_type() {
      return this.specType;
    },
    setForm() {
      if (this.swaped_spec != null) {
        this.form = Object.assign({}, this.swaped_spec);
        this.oldValueForm = Object.assign({}, this.swaped_spec);
      } else {
        this.form = Object.assign({}, this.emtyForm);
      }
    },
    internalNoteLength() {
      return this.form.internal_note != null
          ? this.form.internal_note.length
          : 0;
    },
    manufacturerLength() {
      return this.form.manufacturer != null ? this.form.manufacturer.length : 0;
    },
  },
  mounted() {
    this.setForm;
  },
  methods: {
    saveSwapSpec() {
      console.log("save");
    },
    validateSave() {
      if (
          this.internalNoteLength <= this.maxChar &&
          this.manufacturerLength <= this.maxCharManu
      ) {
        return new Promise((resolve, reject) => {
          this.$refs.specSwapRules.validate().then((success) => {
            if (success) {
              resolve(true);
              this.form.total_price = this.form.unit_cogs * this.qty;
              this.form.total_delivery_install =
                  this.form.unit_cogs *
                  this.qty *
                  (this.form.unit_delivery_install / 100);
              this.form.quantity = this.qty;
              this.form.specType = this.spec_type;
              if (this.spec_type == "ffe") {
                this.form.ffe_spec_id = this.spec_id;
              } else if (this.spec_type == "avit") {
                this.form.avit_spec_id = this.spec_id;
              }
              this.$emit("swapSpec", this.form);
              this.form = Object.assign({}, this.emtyForm);
              this.$refs.specSwapRules.reset();
              this.$bvModal.hide("swap_spec_modal_" + this.specType);
            } else {
              reject();
            }
          });
        });
      }
    },
    resetSpec() {
      this.form.specType = this.spec_type;
      this.form.ffe_spec_id = this.spec_id;
      this.$emit("resetSpec", this.form);
      this.form = Object.assign({}, this.emtyForm);
      this.$refs.specSwapRules.reset();
      this.$bvModal.hide("swap_spec_modal_" + this.specType);
    },
    closeModal() {
      if (this.swaped_spec != null) {
        this.form = Object.assign({}, this.oldValueForm);
      } else {
        this.form = Object.assign({}, this.emtyForm);
      }
      this.$refs.specSwapRules.reset();
      this.$bvModal.hide("swap_spec_modal_" + this.specType);
      this.form;
    },
  },
};
</script>

<style lang="scss" scoped></style>