<template>
  <b-modal
    id="edit-allow"
    size="lg"
    title="Edit Cash Allowances"
    cancel-title="Cancel"
    ok-title="Add Item"
    :no-close-on-backdrop="true"
    @ok="handleOk"
    @show="handleShow"
    @hidden="resetModal"
  >
    <b-overlay :show="show" large rounded="lg" spinner-variant="primary">
      <validation-observer ref="accountRules" tag="form">
        <b-row>
          <b-col md="12">
            <h4 style="padding-bottom: 20px">Item Details</h4>
          </b-col>
          <b-col md="12">
            <validation-provider #default="{ errors }" name="item" rules="required">
              <b-form-group>
                <label for="InputHelp">Item Name</label>
                <b-input-group>
                  <b-form-input
                    v-model="item"
                    type="text"
                    placeholder="Ex. Access Control"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label for="InputHelp">Description for Clients</label>
              <small class="text-muted"> optional</small>
              <b-form-textarea
                id="details"
                v-model="details"
                placeholder="Ex. HID-compatible system, 2x suite entry doors"
                rows="2"
                :state="details.length <= maxChar"
                :class="details.length > maxChar ? 'text-danger' : ''"
              />
              <small
                class="textarea-counter-value float-right"
                :class="details.length > maxChar ? 'bg-danger' : ''"
              >
                <span class="char-count">{{ details.length }}</span> /
                {{ maxChar }}
              </small>
              <small class="text-muted"
                >Shown to clients if the item is not hidden in turnkey.</small
              >
            </b-form-group>
          </b-col>
          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="Internal Note"
              :rules="{
                required: budgetGroup == 'Construction' && selectedRadio == 'adjustment',
              }"
            >
              <b-form-group>
                <label for="InputHelp">Internal Note</label>
                <small
                  class="text-muted"
                  v-if="!(budgetGroup == 'Construction' && selectedRadio == 'adjustment')"
                >
                  optional</small
                >
                <b-form-textarea
                  id="internalNote"
                  v-model="internalNote"
                  placeholder="Internal notes and reason for changes."
                  rows="2"
                  :state="internalNote.length <= maxCharInternal"
                  :class="internalNote.length > maxCharInternal ? 'text-danger' : ''"
                />
                <small
                  class="textarea-counter-value float-right"
                  :class="internalNote.length > maxCharInternal ? 'bg-danger' : ''"
                >
                  <span class="char-count">{{ internalNote.length }}</span> /
                  {{ maxCharInternal }}
                </small>
                <template v-if="errors[0]">
                  <small class="text-danger">{{ errors[0] }}</small
                  ><br />
                </template>
                <small class="text-muted"
                  >This field is required when making an adjustment to base spec.</small
                >
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="12" style="padding-top: 0px">
            <hr style="border: 1px solid rgba(31, 41, 47, 0.05)" />
          </b-col>
          <b-col md="12">
            <h4 style="padding-bottom: 20px">Pricing</h4>
          </b-col>
          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="budget group"
              rules="required"
            >
              <b-form-group>
                <label for="InputHelp">Budget Group</label>
                <v-select
                  v-model="budgetGroup"
                  label="title"
                  :options="option"
                  @input="toggleChanged()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="12">
              <validation-provider
                #default="{ errors }"
                name="Tender Code"
                :rules="{ required: true }"
              >
                <b-form-group>
                  {{ tenderCode }}
                  <label for="InputHelp">Tender Code</label>
                  <!-- <v-select
                    v-model="tenderCode"
                    label="code_name"
                    :options="tc"
                    item-text="code_name"
                    required
                    :reduce="(tenderCode) => tenderCode.cost_code"
                  /> -->
                  <v-select
                      v-model="tenderCode"
                      label="code_name"
                      :options="
                        budgetGroup == 'Construction'
                          ? uniformat.Construction
                          : budgetGroup == 'FF&E'
                          ? uniformat['FF&E']
                          : budgetGroup == 'AV/IT'
                          ? uniformat.AVIT
                          : uniformat['Soft Costs']
                      "
                      item-text="code_name"
                      required
                      :reduce="(tenderCode) => tenderCode.code"
                    />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              </b-col>
          <b-col md="6" v-if="budgetGroup !== 'Construction'">
            <validation-provider
              #default="{ errors }"
              name="price"
              :rules="{ required: budgetGroup !== 'Construction' }"
            >
              <b-form-group>
                <label for="InputHelp"
                  >Total Price
                  {{
                    budgetGroup !== "Construction" && budgetGroup !== "Soft Cost"
                      ? "(w/ D&I)"
                      : ""
                  }}</label
                >
                <b-input-group prepend="$">
                  <b-form-input v-model="price" type="number" placeholder="$0.00" />
                </b-input-group>
                <small class="labelpersqft"
                  >Price/sqft:{{ price / sqft < 0 ? " -" : "" }}
                  {{ "$" + Math.abs(price / sqft).toFixed(2) }}
                </small>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            md="6"
            v-if="selectedRadio !== 'adjustment' && budgetGroup == 'Construction'"
          >
            <validation-provider
              #default="{ errors }"
              name="price"
              :rules="{ required: true }"
            >
              <b-form-group>
                <label for="InputHelp"
                  >Total Price
                  {{
                    budgetGroup !== "Construction" && budgetGroup !== "Soft Cost"
                      ? "(w/ D&I)"
                      : ""
                  }}</label
                >
                <b-input-group prepend="$">
                  <b-form-input v-model="price" type="number" placeholder="$0.00" />
                </b-input-group>
                <small class="labelpersqft"
                  >Price/sqft:{{ price / sqft < 0 ? " -" : "" }}
                  {{ "$" + Math.abs(price / sqft).toFixed(2) }}
                </small>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            md="6"
            v-if="selectedRadio !== 'adjustment' || budgetGroup != 'Construction'"
          >
            <validation-provider #default="{ errors }" name="cost" rules="required">
              <b-form-group>
                <label for="InputHelp">Total COGS</label>
                <b-input-group prepend="$">
                  <b-form-input
                    v-model="cost"
                    type="number"
                    placeholder="$0.00"
                    @keyup="constructionPrice()"
                  />
                </b-input-group>
                <small class="labelpersqft"
                  >COGS / SQFT: {{ cost / sqft < 0 ? " -" : "" }}
                  {{
                    "$" +
                    Number(Math.abs(cost / sqft).toFixed(2)).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })
                  }}</small
                ><br />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            md="12"
            v-if="selectedRadio == 'adjustment' && budgetGroup == 'Construction'"
          >
            <b-row>
              <b-col md="3"
                >{{ budgetGroup }}
                <label
                  style="font-weight: 600;font-size: 12px;line-height: 23px;letter-spacing: 0.6px;text-transform: uppercase;color: #B9B9C3;1"
                  >Quantity
                </label>
                <p
                  style="
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #6e6b7b;
                  "
                >
                  {{ selectedConstructionItem.qty + qty * 1 < 0 ? " -" : "" }}
                  {{
                    constructionItem != ""
                      ? Number(
                          Math.abs(selectedConstructionItem.qty + qty * 1).toFixed(2)
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        }) +
                        " " +
                        selectedConstructionItem.unit_type
                      : "-"
                  }}
                  <span :class="qty > 0 ? 'increase' : 'decrease'" v-if="qty != 0">
                    <uil-arrow-up v-if="qty > 0" />
                    <uil-arrow-down v-else />{{
                      Number(Math.abs(qty).toFixed(2)).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    }}
                  </span>
                </p>
              </b-col>
              <b-col md="3">
                <label
                  style="font-weight: 600;font-size: 12px;line-height: 23px;letter-spacing: 0.6px;text-transform: uppercase;color: #B9B9C3;1"
                  >COGS / Unit</label
                >
                <p
                  style="
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #6e6b7b;
                  "
                >
                  {{ selectedConstructionItem.unit_cost + unitCost * 1 < 0 ? " -" : "" }}
                  {{
                    constructionItem != ""
                      ? "$" +
                        Number(
                          Math.abs(
                            selectedConstructionItem.unit_cost + unitCost * 1
                          ).toFixed(2)
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })
                      : "-"
                  }}
                  <span
                    :class="unitCost > 0 ? 'increase' : 'decrease'"
                    v-if="unitCost != 0"
                  >
                    <uil-arrow-up v-if="unitCost > 0" />
                    <uil-arrow-down v-else />{{ Number(Math.abs(unitCost)).toFixed(2) }}
                  </span>
                </p>
              </b-col>
              <b-col md="3">
                <label
                  style="font-weight: 600;font-size: 12px;line-height: 23px;letter-spacing: 0.6px;text-transform: uppercase;color: #B9B9C3;1"
                  >Total COGS</label
                >
                <p
                  style="
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #6e6b7b;
                  "
                >
                  {{
                    (selectedConstructionItem.qty + qty * 1) *
                      (selectedConstructionItem.unit_cost + unitCost * 1) <
                    0
                      ? " -"
                      : ""
                  }}
                  {{
                    constructionItem != ""
                      ? "$" +
                        Number(
                          Math.abs(
                            (selectedConstructionItem.qty + qty * 1) *
                              (selectedConstructionItem.unit_cost + unitCost * 1)
                          ).toFixed(2)
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })
                      : "-"
                  }}
                  <span
                    :class="
                      selectedConstructionItem.total_cost <
                      (selectedConstructionItem.qty + qty * 1) *
                        (selectedConstructionItem.unit_cost + unitCost * 1)
                        ? 'increase'
                        : 'decrease'
                    "
                    v-if="qty != 0 || unitCost != 0"
                  >
                    <uil-arrow-up
                      v-if="
                        selectedConstructionItem.total_cost <
                        (selectedConstructionItem.qty + qty * 1) *
                          (selectedConstructionItem.unit_cost + unitCost * 1)
                      "
                    />
                    <uil-arrow-down v-else />{{
                      Number(
                        Math.abs(
                          selectedConstructionItem.total_cost -
                            (selectedConstructionItem.qty + qty * 1) *
                              (selectedConstructionItem.unit_cost + unitCost * 1)
                        ).toFixed(2)
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    }}
                  </span>
                </p>
              </b-col>
              <b-col md="3">
                <label
                  style="font-weight: 600;font-size: 12px;line-height: 23px;letter-spacing: 0.6px;text-transform: uppercase;color: #B9B9C3;1"
                  >Total COGS / sqft</label
                >
                <p
                  style="
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #6e6b7b;
                  "
                >
                  {{
                    ((selectedConstructionItem.qty + qty * 1) *
                      (selectedConstructionItem.unit_cost + unitCost * 1)) /
                      sqft <
                    0
                      ? " -"
                      : ""
                  }}
                  {{
                    constructionItem != ""
                      ? "$" +
                        Number(
                          Math.abs(
                            ((selectedConstructionItem.qty + qty * 1) *
                              (selectedConstructionItem.unit_cost + unitCost * 1)) /
                              sqft
                          ).toFixed(2)
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })
                      : "-"
                  }}
                  <span
                    :class="
                      selectedConstructionItem.cost_per_sqft <
                      ((selectedConstructionItem.qty + qty * 1) *
                        (selectedConstructionItem.unit_cost + unitCost * 1)) /
                        sqft
                        ? 'increase'
                        : 'decrease'
                    "
                    v-if="qty != 0 || unitCost != 0"
                  >
                    <uil-arrow-up
                      v-if="
                        selectedConstructionItem.cost_per_sqft <
                        ((selectedConstructionItem.qty + qty * 1) *
                          (selectedConstructionItem.unit_cost + unitCost * 1)) /
                          sqft
                      "
                    />
                    <uil-arrow-down v-else />{{
                      Number(
                        Math.abs(
                          selectedConstructionItem.cost_per_sqft -
                            parseFloat(
                              ((selectedConstructionItem.qty + qty * 1) *
                                (selectedConstructionItem.unit_cost + unitCost * 1)) /
                                sqft
                            ).toFixed(2)
                        )
                      ).toLocaleString(undefined, { minimumFractionDigits: 2 })
                    }}
                  </span>
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </validation-observer>
      <template #overlay>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle" />
          <strong> Loading...</strong>
        </div>
      </template>
    </b-overlay>
    <template #modal-footer="{ ok, cancel }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <b-col style="padding-left: 0px">
        <b-button variant="danger" @click="deleteOption()">
          <feather-icon icon="Trash2Icon" />
          Delete
        </b-button>
      </b-col>
      <b-col style="text-align: end; padding-right: 0px">
        <!-- Button with custom close trigger value -->
        <b-button
          variant="outline-secondary"
          style="margin-right: 10px"
          @click="cancel()"
        >
          Cancel
        </b-button>

        <b-button variant="primary" @click="ok()"> Save Option </b-button>
      </b-col>
    </template>
  </b-modal>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormFile,
  BCardText,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BTable,
  BOverlay,
  BButton,
  BFormTextarea,
  BSpinner,
  BFormCheckbox,
  BFormRadioGroup,
} from "bootstrap-vue";
import { required } from "@validations";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import tenderCodes from "@/assets/objects/tender-codes.json";
import { UilArrowUp, UilArrowDown } from "@iconscout/vue-unicons";
import uniformat from "@/assets/objects/uniformat-codes.json";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BCardText,
    BTable,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    vSelect,
    BFormInvalidFeedback,
    LottieAnimation,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormTextarea,
    BSpinner,
    BFormCheckbox,
    BFormRadioGroup,
    UilArrowUp,
    UilArrowDown,
  },
  props: [
    "quoteId",
    "sqft",
    "cash_allowance_to_edit",
    "constructionItems",
    "base_spec_version",
  ],
  data() {
    return {
      userName: localStorage.getItem("name"),
      show: false,
      item: "",
      details: "",
      internalNote: "",
      price: 0,
      cost: 0,
      unitCost: 0,
      qty: 0,
      fields: {
        details: "",
      },
      budget_group: "",
      tenderCode: "",
      constructionItem: "",
      selectedConstructionItem: [],
      required,
      option: ["FF&E", "AV/IT", "Construction", "Soft Cost"],
      maxChar: 500,
      maxCharInternal: 500,
      isBaseSpec: false,
      budgetGroup: "",
      id: 0,
      tenderCodes: tenderCodes,
      uniformat: uniformat,
      selectedRadio: "new",
      optionsRadio: [
        { text: "New Item", value: "new" },
        { text: "Base Spec Adjustment", value: "adjustment" },
      ],
      isAdjustment: false,
      constructionWorkId: null,
      temp_id: undefined,
      order: 0,
    };
  },
  computed: {
    tc() {
      let tc = [];
      if (Number(this.base_spec_version) < 3) {
        tc = tenderCodes.v1;
      } else {
        tc = tenderCodes.v2.filter((c) => c.cost_code != "0901");
      }
      return tc;
    },
  },
  watch: {
    cash_allowance_to_edit(newValue, oldValue) {
      this.item = newValue.name;
      this.details = newValue.details ? newValue.details : "";
      this.price = newValue.total_price;
      this.cost = newValue.total_cost;
      this.id = newValue.id;
      this.isBaseSpec = newValue.is_base_spec;
      this.id = newValue.id;
      this.order = newValue.order;
      this.temp_id = newValue.temp_id;
      this.budgetGroup = newValue.budget_group;
      this.tenderCode = newValue.uniformat;
      this.isAdjustment = newValue.is_adjustment;
      this.qty = newValue.quantity;
      this.unitCost = newValue.unit_cost;
      this.isAdjustment = newValue.is_adjustment;
      this.internalNote = newValue.internal_note ? newValue.internal_note : "";
      if (this.isAdjustment) {
        this.constructionWorkId = newValue.construction_work_id;
        const constructionWorkId = this.constructionWorkId;
        this.selectedConstructionItem = this.constructionItems.find(function (e) {
          return e.construction_work_id == constructionWorkId;
        });
        this.constructionItem = this.selectedConstructionItem.name;
        this.selectedRadio = "adjustment";
      }
    },
  },
  mounted() {},
  methods: {
    constructionItemChange(value) {
      if (value == null) {
        this.constructionItem = "";
        this.isAdjustment = true;
        this.qty = 0;
        this.unitCost = 0;
        this.selectedConstructionItem = [];
      } else {
        this.selectedConstructionItem = this.constructionItems.find(function (e) {
          return e.construction_work_id == value;
        });
        this.isAdjustment = true;
        this.isBaseSpec = true;
        this.constructionWorkId = this.selectedConstructionItem.construction_work_id;
        this.tenderCode = this.selectedConstructionItem.uniformat;
      }
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.validationForm();
    },
    handleShow() {
      this.item = this.cash_allowance_to_edit.name;
      this.details = this.cash_allowance_to_edit.details
        ? this.cash_allowance_to_edit.details
        : "";
      this.price = this.cash_allowance_to_edit.total_price;
      this.cost = this.cash_allowance_to_edit.total_cost;
      this.id = this.cash_allowance_to_edit.id;
      this.isBaseSpec = this.cash_allowance_to_edit.is_base_spec;
      this.id = this.cash_allowance_to_edit.id;
      this.order = this.cash_allowance_to_edit.order;
      this.temp_id = this.cash_allowance_to_edit.temp_id;
      this.budgetGroup = this.cash_allowance_to_edit.budget_group;
      this.tenderCode = this.cash_allowance_to_edit.uniformat;
      this.isAdjustment = this.cash_allowance_to_edit.is_adjustment;
      this.qty = this.cash_allowance_to_edit.quantity;
      this.unitCost = this.cash_allowance_to_edit.unit_cost;
      this.isAdjustment = this.cash_allowance_to_edit.is_adjustment;
      this.internalNote = this.cash_allowance_to_edit.internal_note
        ? this.cash_allowance_to_edit.internal_note
        : "";
      if (this.isAdjustment) {
        this.constructionWorkId = this.cash_allowance_to_edit.construction_work_id;
        const constructionWorkId = this.constructionWorkId;
        this.selectedConstructionItem = this.constructionItems.find(function (e) {
          return e.construction_work_id == constructionWorkId;
        });
        this.constructionItem = this.selectedConstructionItem.name;
        this.selectedRadio = "adjustment";
      }
    },
    resetModal() {
      this.fields = {
        budget_group: "",
        cost: 0,
        details: "",
        item: "",
        price: "",
        quoteId: "",
        sqft: 0,
        total_cost: 0,
        total_price: 0,
        price_per_sqft: 0,
        cost_per_sqft: 0,
        quantity: 0,
        unit_cost: 0,
        is_adjustment: false,
        client_description: "",
        construction_work_id: "",
        id: null,
        temp_id: undefined,
        order: 0,
      };
      this.item = "";
      this.details = "";
      this.price = 0;
      this.cost = 0;
      this.budgetGroup = "";
      this.isBaseSpec = false;
      this.tenderCode = "";
      this.internalNote = "";
      this.isAdjustment = false;
      this.qty = 0;
      this.unitCost = 0;
      this.selectedConstructionItem = [];
      this.isBaseSpec = false;
      this.constructionItem = "";
      this.internalNote = "";
      this.constructionWorkId = null;
      this.selectedRadio = "new";
      this.id = null;
      this.order = 0;
    },
    constructionPrice() {
      if (this.budgetGroup == "Construction") {
        // this.price = this.cost;
      }
    },
    isEmpty() {
      if (this.unitCost == "") {
        this.unitCost = 0;
      }
      if (this.qty == "") {
        this.qty = 0;
      }
    },
    toggleChanged() {
      if (this.budgetGroup == "Construction" && this.selectedRadio == "new") {
        this.price = this.cost = 0;
      } else if (this.budgetGroup == "Construction") {
        this.price = this.cost;
      } else if (this.budgetGroup == "Soft Cost" || this.budgetGroup == "AV/IT") {
        this.price = this.cost;
        this.isBaseSpec = false;
      } else {
        this.tenderCode = null;
        this.price = this.cost = 0;
      }
        this.tenderCode = null;
    },
    radioChanged(checked) {
      if (this.isAdjustment == true && checked == "new") {
        this.isAdjustment = false;
        this.qty = 0;
        this.unitCost = 0;
        this.price = 0;
        this.cost = 0;
        this.selectedConstructionItem = [];
        this.isBaseSpec = false;
        this.constructionItem = "";
        this.constructionWorkId = null;
        this.tenderCode = null;
      } else if (checked == "adjustment") {
        this.isAdjustment = true;
        this.isBaseSpec = true;
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (this.isAdjustment == true) {
            this.cost =
              (this.selectedConstructionItem.qty + this.qty * 1) *
                (this.selectedConstructionItem.unit_cost + this.unitCost * 1) -
              this.selectedConstructionItem.total_cost;
            this.price = this.cost;
          } else {
            this.qty = 0;
            this.unitCost = 0;
            this.selectedConstructionItem = [];
            this.constructionItem = "";
            this.constructionWorkId = null;
          }
          if (success) {
            resolve(true);
            this.show = true;
            this.fields = {
              details: this.details,
              name: this.item,
              sqft: this.sqft,
              quoteId: this.quoteId,
              total_price: this.price,
              total_cost: this.cost,
              cost_per_sqft: parseFloat(this.cost / this.sqft).toFixed(2),
              price_per_sqft: parseFloat(this.price / this.sqft).toFixed(2),
              budget_group: this.budgetGroup,
              is_base_spec: this.isBaseSpec,
              // tender_code: this.tenderCode,
              quantity: this.qty,
              unit_cost: this.unitCost,
              is_adjustment: this.isAdjustment,
              internal_note: this.internalNote,
              construction_work_id: this.constructionWorkId,
              id: this.id,
              temp_id: this.temp_id,
              order: this.order,
              uniformat: this.tenderCode,
              quote_id: this.quoteId,
            };
            this.$emit("updatedAllowances", this.fields);
            this.isBusy = false;
            this.show = false;
            this.$bvModal.hide("edit-allow");
            // this.fields.price_per_sqft = parseFloat(this.fields.total_price/this.sqft).toFixed(2);
            // this.fields.cost_per_sqft = parseFloat(this.fields.total_cost/this.sqft).toFixed(2);
            // this.$http
            //   .put(`/quote/${this.quoteId}/allowances/${this.id}`, this.fields)
            //   .then((response) => {
            //     this.$emit("updatedAllowances", response.data);
            //     this.show = false;
            //     this.$bvModal.hide("edit-allow");
            //   })
            //   .catch((error) => {
            //     this.show = false;
            //     this.showToast("danger", error.response.data.message);
            //   });
          } else {
            reject();
          }
        });
      });
    },
    deleteOption() {
      this.$emit("delete_cash_allowance", {
        details: this.details,
        name: this.item,
        sqft: this.sqft,
        quoteId: this.quoteId,
        total_price: this.price,
        total_cost: this.cost,
        cost_per_sqft: parseFloat(this.cost / this.sqft).toFixed(2),
        price_per_sqft: parseFloat(this.price / this.sqft).toFixed(2),
        budget_group: this.budgetGroup,
        is_base_spec: this.isBaseSpec,
        tender_code: this.tenderCode,
        quantity: this.qty,
        unit_cost: this.unitCost,
        is_adjustment: this.isAdjustment,
        internal_note: this.internalNote,
        construction_work_id: this.constructionWorkId,
        id: this.id,
        temp_id: this.temp_id,
        order: this.order,
        quote_id:this.quoteId
      });

      this.$bvModal.hide("edit-allow");
      this.show = true;
      //   this.$http
      //     .delete(`/quote/${this.quoteId}/allowances/${this.id}`)
      //     .then((response) => {
      //       this.show = false;
      //       this.$emit("updatedAllowances", response.data);
      //       this.showToast(
      //         "success",
      //         "Cash Allowance successfully deleted.",
      //         "Deleted Successfully"
      //       );
      //       this.$bvModal.hide("edit-allow");
      //     })
      //     .catch((error) => {
      //       this.show = false;
      //       this.showToast("danger", "Error Deleting Cash Allowance", "Notification");
      //       this.$bvModal.hide("edit-allow");
      //     });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style lang="scss" scoped>
.increase {
  color:  rgba(59, 171, 255, 1) ;
  font-size: 12px;
}

.decrease {
  color: #ea5455;
  font-size: 12px;
}
</style>
